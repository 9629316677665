import React from "react";
import {graphql} from "gatsby";

import {Layout} from 'components';

export default function Template({data}) {
    const {markdownRemark} = data;
    const {frontmatter, html} = markdownRemark;
    const pageTitle = `${frontmatter.title} - Fundamental`;
    return (
        <Layout title={pageTitle}>
            <div className="article">
                <h1>{frontmatter.title}</h1>
                <time>{frontmatter.date}</time>
                <div
                    className="article-content"
                    dangerouslySetInnerHTML={{__html: html}}
                />
            </div>
        </Layout>
    );
}

export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: {path: {eq: $path}}) {
        html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                title
            }
        }
    }
`;
